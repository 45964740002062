.navbar {
  background-color: #D0CFCF;
  height: 50px;
}

.navbar-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0;
  font-size: 1.5rem;
  max-width: 1170px;
}

.nav-menu {
  display: none;
}

.nav-menu-active {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: 90vh;
  display: block;
  text-align: center;
  list-style-type: none;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 25px 23px -27px rgba(0,0,0,0.7) inset;
  padding-top: 20px;
}

.nav-menu-active a {
  text-decoration: none;
  display: block;
  color: black;
  cursor: pointer;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 10px;
}

.menu-icon {
  cursor: pointer;
  color: #7A7D7D;
}

.nav-item-hide {
  display: none;
}

@media (min-width: 960px) {
  .nav-menu {
    display: inline;
  }

  .nav-menu .nav-item {
    display: inline;
    margin-left: 55px;
    font-size: 1.2rem;
  }

  .nav-links {
    text-decoration: none;
    color: #7A7D7D;
  }

  .nav-links:hover {
    color: black;
    transition: 0.3s all linear;
  }

  .menu-icon {
    display: none;
  }
}

@media (max-width: 420px) {
  .navbar-container {
    width: 90%;
  }
}
