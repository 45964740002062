@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-family: 'Titillium Web', sans-serif;
}


.header {
  background-color: #D0CFCF;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 5px 15px rgba(0,0,0,0.4);
}

.header-container {
  margin: 0 auto;
  width: 80%;
  padding: 20px 0px;
  position: relative;
  height: 70px;
  max-width: 1170px;
}

.main-logo {
  position: absolute;
  top: 11px;
  left: 0;
  cursor: pointer;
}

.info-container {
  display: flex;
  position: absolute;
  top: 10px;
  right: 0;
}

.quote-container {
  position: relative;
}

.phone-container {
  position: relative;
}

.header-icon {
  position: absolute;
  display: grid;
  place-items: center;
  left: -40px;
  top: 15px;
  font-size: 1.6rem;
}

.mob {
  color: black;
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  text-decoration: none;
}


@media (max-width: 960px) {
  .quote-container {
    display: none;
  }
}

@media (min-width: 960px) {
  .phone-container {
    margin-left: 70px;
  }
}

@media (max-width: 420px) {
  .header-container {
    width: 90%;
  }
  .header-icon {
    display: none;
  }
}
.navbar {
  background-color: #D0CFCF;
  height: 50px;
}

.navbar-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0;
  font-size: 1.5rem;
  max-width: 1170px;
}

.nav-menu {
  display: none;
}

.nav-menu-active {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: 90vh;
  display: block;
  text-align: center;
  list-style-type: none;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 25px 23px -27px rgba(0,0,0,0.7) inset;
  padding-top: 20px;
}

.nav-menu-active a {
  text-decoration: none;
  display: block;
  color: black;
  cursor: pointer;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 10px;
}

.menu-icon {
  cursor: pointer;
  color: #7A7D7D;
}

.nav-item-hide {
  display: none;
}

@media (min-width: 960px) {
  .nav-menu {
    display: inline;
  }

  .nav-menu .nav-item {
    display: inline;
    margin-left: 55px;
    font-size: 1.2rem;
  }

  .nav-links {
    text-decoration: none;
    color: #7A7D7D;
  }

  .nav-links:hover {
    color: black;
    transition: 0.3s all linear;
  }

  .menu-icon {
    display: none;
  }
}

@media (max-width: 420px) {
  .navbar-container {
    width: 90%;
  }
}

.footer-section {
  background-color: black;
  color: #fff;
  height: 100px;
}

.footer-section-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-section-container p {
  font-size: 0.8rem;
}

.footer-section-container a {
  color: #fff;
}

.footer-section-container a:hover {
  color: #7a7d7d;
}
.hero-section {
  background-color: #fff;
}

.hero-section-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: 1170px;
}

.image-container {
  position: relative;
  color: white;
}

.cta-image {
  width: 100%;
  height: auto;
  display: block;
}

.nic-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30%;
}

.main-title {
  font-size: 1.7rem;
}

.strapline {
  font-size: 1.2rem;
}

.main-text-cont {
  position: absolute;
  top: 50%;
  padding-left: 15px;
  transform: translateY(-50%);
}

.areas-list-container {
  width: 100%;
  background-color: #7A7D7D;
  color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.areas-text {
  padding: 10px 0;
}

.intro {
  display: none;
}

.areas-list {
  padding-top: 5px;
  list-style: inside;
}

.black-heading {
  color: black;
}

.lightning {
  margin-right: 5px;
}

.call-us-cont {
  padding-top: 10px;
}

@media (min-width: 960px) {
  .hero-section-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .image-container {
    width: 65%;
  }
  .areas-list-container {
    width: 35%;
  }
  .areas-section {
    width: 100%;
  }
  .call-us-cont {
    margin-top: auto;
  }
  .main-title {
    font-size: 2rem;
  }
  .main-text-cont {
    line-height: 1.7;
  }
  .strapline {
    font-size: 1.4rem;
  }
}

.call-us-now {
  font-size: 2rem;
  line-height: 1;
}

.number {
  color: black;
  font-size: 2rem;
  text-decoration: none;
}

@media (min-width: 1250px) {
  .main-text-cont {
    letter-spacing: 1px;
  }
  .main-title {
    font-size: 2.5rem;
  }
  .strapline {
    font-size: 1.5rem;
  }
  .intro {
    display: block;
  }
  .areas {
    padding-top: 20px;
  }
}

@media (max-width: 420px) {
  .hero-section-container {
    width: 90%;
  }
}



.jobs-section-container {
  margin: 0 auto;
  padding: 15px 0;
  width: 100%;
}

.jobs-title {
  text-align: center;
  margin-bottom: 25px;
}

.job-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 15px;
  gap: 15px 15px;
}

@media (min-width: 960px) {
  .job-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .job-cards {
    display: block;
  }
  .job-item {
    margin-bottom: 15px;
  }
  .jobs-section-container {
    padding-bottom: 0;
  }
}



.job-item {
  background-color: #D0CFCF;
  color: black;
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 6px;
}

.bolt {
  color: red;
  margin-right: 8px;
  flex-shrink: 0;
}


.service-item {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #D0CFCF;
  margin-bottom: 30px;
}

.service-image {
  display: block;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.service-image-container {
  position: relative;
}

.service-icon-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 2.5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7A7D7D;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  border: 5px solid #D0CFCF;
}

.service-icon-container img {
  width: 44px;
}

.service-details {
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.service-title {
  font-size: 1.4rem;
  text-align: center;
}

.service-text {
  padding: 15px 0;
}

.service-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}
.services-section {
  background: #fff;
  padding-bottom: 50px;
}

.services-section-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1170px;
}

.services-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
}

.service-cards {
  list-style-type: none;
  display: block;
}

@media (min-width: 933px) {
  .service-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    gap: 25px;
  }
}

@media (max-width: 420px) {
  .services-section-container {
    width: 90%;
  }
}
.banner-section {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/fixed-background.74dc102e.jpg) center/cover fixed no-repeat;
}

.banner-container {
  padding: 150px 0;
}
.project-section {
  background-color: #fff;
}

.project-section-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1170px;
}

.projects-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
}

@media (min-width: 693px) {
  .project-gallery-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }
}

@media (min-width: 1060px) {
  .project-gallery-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 420px) {
  .project-section-container {
    width: 90%;
  }
}
.project-image-container {
  position: relative;
  color: white;
  margin-bottom: 20px;
}

@media (min-width: 693px ) {
  .project-image-container {
    margin-bottom: 0px;
  }
}

.project-info {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.project-info:hover {
  opacity: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  transition: all 0.2s linear;
}

.project-image {
  display: block;
  width: 100%;
}

.project-heading {
  padding: 10px 0;
}

.project-desc {
  padding: 10px 0;
}

.zoom {
  padding: 10px 0;
}




.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: grid;
  place-items: center;
  cursor: zoom-out;
}

.modal-img-cont {
  width: 60%;
  height: auto;
  position: relative;
  z-index: 2001;
  cursor: default;
}

.modal-img-cont-portrait {
  width: 550px;
  height: auto;
  position: relative;
  z-index: 2001;
  cursor: default;
}

.modal-img {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1002;
  padding-right: 15px;
}

.close-btn {
  position: absolute;
  top: -25px;
  right: 15px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
  font-size: 1.2rem;
}

.close-btn:hover {
  opacity: 1;
}

.modal-open {
  height: 100vh;
  overflow-y:hidden;
  padding-right: 15px;
}




.reviews-section {
  background-color: #fff;
}

.reviews-section-standalone {
  min-height: calc(100vh - 220px);
}

.reviews-section-container {
  width: 85%;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 70px;
}

.reviews-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
  padding-top: 20px;
}

.slider-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
}

.section-center {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.section-center article {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  opacity: 0;
  transition: all 0.3s linear;
}

.section-center article.activeSlide {
  position: relative;
  opacity: 1;
  transform: translateX(0);
}

.section-center article.lastSlide {
  transform: translateX(-100%);
}

.section-center article.nextSlide {
  transform: translateX(100%);
}

.review-image-container {
  width: 100%;
  border-top: 5px solid #D0CFCF;
  border-bottom: 5px solid #D0CFCF;
  border-left: 5px solid #D0CFCF;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.review-image-container img {
  width: 100%;
  display: block;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.review-text-container {
  background-color: #7a7d7d;
  padding: 20px 40px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  color: #fff;
  border-top: 5px solid #D0CFCF;
  border-bottom: 5px solid #D0CFCF;
  border-right: 5px solid #D0CFCF;
}

.quote-left, .quote-right {
  position: absolute;
  font-size: 3rem;
  opacity: 0.15;
}

.quote-left {
  top: 20px;
  left: 20px;
}

.quote-right {
  bottom: 20px;
  right: 20px;
}

.review-footer {
  display: flex;
  justify-content: flex-end;
}

.prev, .next {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: #D0CFCF;
  border: 2px solid #fff;
  border-radius: 5px;
  color: red;
  box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.6);
  outline: none;
}

.prev {
  left: 0;
  transform: translate( -50%, -50%);
}

.next {
  right: 0;
  transform: translate(50%, -50%); 
}

.prev:hover {
  background-color: #7A7D7D;
  color: #fff;
  transition: all 0.3s linear;
}

.next:hover {
  background-color: #7A7D7D;
  color: #fff;
  transition: all 0.3s linear;
}

@media (min-width: 960px) {
  .prev, .next {
    font-size: 3rem;
  }
}


.contact-section {
  background-color: #d0cfcf;
}

.contact-section-standalone {
  min-height: calc(100vh - 220px);
  background-color: #d0cfcf;
}

.contact-section-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  max-width: 1170px;
}

.contact-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
}

.contact-text-cont {
  width: 100%;
  padding-bottom: 30px;
}

.contact-details-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.contact-details {
  line-height: 1.8;
  display: flex;
  flex-direction: column;
}

.cont {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.cont a {
  margin-left: 7px;
  text-decoration: none;
  color: black;
}

.social-link {
  margin-right: 5px;
  font-size:  2.4rem;
  cursor: pointer;
  color: #7a7d7d;
  display: flex;
}

.social-link:hover {
  color: black;
  transition: all 0.3s linear;
}

.contact-logo {
  width: 120px;
  height: 94px;
}

.social-media-links-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
  margin-left: -4px;
}

@media (min-width: 960px) {
  .contact-info-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 50px;
    gap: 50px;
  }
  .contact-text-cont {
    padding-bottom: 0;
    text-align: justify;
  }
}

@media (max-width: 420px) {
  .contact-section-container {
    width: 90%;
  }
}

.notfound-section {
  background-color: #fff;
  min-height: calc(100vh - 220px);
}

.notfound-section-container {
  width: 85%;
  margin: 0 auto;
}

.notfound-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2.5rem;
  padding-top: 20px;
}
